import React, { useEffect, useState } from 'react'
import Container from '../layout/Container'
import { observer } from 'mobx-react'
import Table from '../table/Table'
import Pagination from '../table/Pagination'
import { Props } from '../../store/types.d'
import { TableCellAction } from '../table/TableCellEditButton'
import { Link } from 'react-router-dom'
import { debounceSearch } from '../../utils/debounce'


const AddressesPage: React.FC<Props> = ({ rootStore }) => {
    const { addressStore, setTitle } = rootStore

    const { searchItem, isApiError, isLoading, apiErrorMessage, addressData, fetchAddresses, isPageOpened } = addressStore
    const [searchItemField, setSearchItemField] = useState(searchItem)

    const loadPage = (page: number) => {
        fetchAddresses(page, searchItem)
    }

    const handleOnChangeSearchItem = (e: any) => {
        setSearchItemField(e.target.value.trim())
        debounceSearch(() => fetchAddresses(1, e.target.value.trim()))
    }

    useEffect(() => {
        setTitle('Manage Office Address | OathZephyr')
        if(rootStore.authStore.isAdmin && !isPageOpened){
            fetchAddresses(1, '')
        }
    }, [])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
                <div className='row ml-auto'>
                    Office Addresses
                    <Link to='/addresses/add' className='ml-auto px-3'>Add Office Address</Link>
                </div>
            </div>

            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='row'>

                            <div className='col-auto ml-auto d-flex'>
                                <input className="form-control mb-4 " name='search' defaultValue={searchItemField} type="text" placeholder='Search' onChange={handleOnChangeSearchItem} aria-label="Search" />
                            </div>

                        </div>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' > {apiErrorMessage} </div>}

                        <div className='table-responsive'>
                            <Table
                                isLoading={isLoading}
                                unique_key='_id'
                                columns={[
                                    { name: 'title', title: 'Title' },
                                    { name: 'street', title: 'Street' },
                                    { name: 'city', title: 'City' },
                                    { name: 'county', title: 'County' },
                                    { name: 'state', title: 'State' },
                                    { name: 'zip_code', title: 'Zip Code' },
                                    { name: 'phone_number', title: 'Phone Number' },
                                    { name: '_id', title: 'Action', component: TableCellAction }
                                ]}
                                data={addressData.page_items}
                            ></Table>
                        </div>

                        <Pagination
                            currentPage={addressData.currentPage}
                            totalPages={addressData.totalPage}
                            totalItems={addressData.totalItem}
                            itemsPerPage={addressData.itemPerPage}
                            isFiltered={addressData.isFiltered}
                            totalAllItems={addressData.totalAllItems}
                            onItemClick={loadPage}
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(AddressesPage)
