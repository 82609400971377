import { observable } from 'mobx'
import RootStore from './Root'
import { DeedFilingProps, ValueLabel, UserEmailWithStatus } from './types.d'
import { getDeedFiling, editRejectedFlag, editRefiledFlag, editFiledFlag, editFilingFlag, editFilingCompleted, getUsersEmailForDeedFilling } from '../api/DeedFilingActions'
import { initialTableDataWithPaginationDetails, initialDeedFiling } from './InitialValues'

interface DeedFilingData {
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    isFiltered: boolean,
    totalAllItems: number,
    page_items: Array<DeedFilingProps>
}

interface MarkFilingData {
    _id: string, 
    filing: string, 
    filing_notes: string,
    is_note_changed: boolean
}

interface MarkFiledData {
    _id: string, 
    filed: string, 
    filed_notes: string,
    is_note_changed: boolean
}

interface MarkRejectedData {
    _id: string, 
    is_rejected: boolean, 
    rejected_notes: string,
    is_note_changed: boolean
}

interface MarkReFiledData {
    _id: string,
    is_refiled: boolean, 
    refiled_notes: string,
    is_note_changed: boolean
}

class DeedFilingStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public excludeTestDeeds: boolean
    @observable public deedsFilingDetails: DeedFilingProps
    @observable public deedFilingData: DeedFilingData
    @observable public searchItem: string
    @observable public filterWithUserId: string
    @observable public searchByStatus: Array<ValueLabel>
    @observable public page: number
    @observable public filterWithRejected: boolean
    @observable public filterWithCompleted: string
    @observable public tableRows: Array<DeedFilingProps>
    @observable public filterWithFiling: string
    @observable public filterWithFiled: string
    @observable public filterWithState: string
    @observable public usersForMarketByOptions: Array<UserEmailWithStatus>
    @observable public isPageOpened: boolean


    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.isPageOpened = false
        this.excludeTestDeeds = true
        this.searchItem = ''
        this.page = 1
        this.searchByStatus = []
        this.deedsFilingDetails = initialDeedFiling
        this.deedFilingData = initialTableDataWithPaginationDetails
        this.tableRows = []
        this.filterWithRejected = false
        this.filterWithCompleted = 'is_not_completed'
        this.filterWithFiling = 'ALL'
        this.filterWithFiled = 'ALL'
        this.filterWithUserId = 'ALL'
        this.filterWithState = 'all'
        this.usersForMarketByOptions = []
    }


    fetchUserEmail = () => {
        this.isApiError = false
        this.apiErrorMessage = ''

        getUsersEmailForDeedFilling().then((response) => {
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            const userEmails = response.data.data
            this.usersForMarketByOptions = userEmails.map((list: any) => {
                const data = {
                    value: list._id,
                    label: list.email,
                    status: list.status
                }
                return data
            })
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    //get the list of deeds with details
    fetchDeedFiling = (page: number, searchItem: string, filterWithUserId: string, filterWithRejected: boolean, filterWithCompleted: string, filterWithFiling: string, filterWithFiled: string, excludeTestDeeds: boolean, filterWithState: string): void => {
        this.isPageOpened = true
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = { 
            page: page, 
            search_item: searchItem,
            filterWithUserId,
            filterWithRejected,
            filterWithCompleted,
            filterWithFiling,
            filterWithFiled,
            excludeTestDeeds,
            filterWithState
        }
        this.page = page
        this.searchItem = searchItem
        this.filterWithRejected = filterWithRejected
        this.filterWithCompleted = filterWithCompleted
        this.filterWithFiling = filterWithFiling
        this.filterWithFiled = filterWithFiled
        this.filterWithUserId = filterWithUserId
        this.filterWithState = filterWithState
        this.excludeTestDeeds = excludeTestDeeds

        getDeedFiling(requestData).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.deedFilingData = response.data.data
            this.tableRows = response.data.data.page_items
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    markFiling(matterId: string, filing: string, filing_notes: string, isNoteChanged: boolean) {
        const data: MarkFilingData = { _id: matterId, filing: filing, filing_notes: filing_notes, is_note_changed: isNoteChanged } 
        this.isLoading = true
        editFilingFlag(data).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.fetchDeedFiling(this.page, this.searchItem, this.filterWithUserId, this.filterWithRejected, this.filterWithCompleted, this.filterWithFiling, this.filterWithFiled, this.excludeTestDeeds, this.filterWithState)
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    markFiled(matterId: string, filed: string, filed_notes: string, isNoteChanged: boolean) {
        const data: MarkFiledData = { _id: matterId, filed: filed, filed_notes: filed_notes, is_note_changed: isNoteChanged } 
        this.isLoading = true
        editFiledFlag(data).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.fetchDeedFiling(this.page, this.searchItem, this.filterWithUserId, this.filterWithRejected, this.filterWithCompleted, this.filterWithFiling, this.filterWithFiled, this.excludeTestDeeds, this.filterWithState)
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    markRejected(matterId: string, isRejected: boolean, rejectedNotes: string, isNoteChanged: boolean) {
        const data: MarkRejectedData = { _id: matterId, is_rejected: isRejected, rejected_notes: rejectedNotes, is_note_changed: isNoteChanged } 
        this.isLoading = true
        editRejectedFlag(data).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.fetchDeedFiling(this.page, this.searchItem, this.filterWithUserId, this.filterWithRejected, this.filterWithCompleted, this.filterWithFiling, this.filterWithFiled, this.excludeTestDeeds, this.filterWithState)
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    markFilingRefiled(matterId: string, isRefiled: boolean, refiledNotes: string, isNoteChanged: boolean) {
        const data: MarkReFiledData = { _id: matterId, is_refiled: isRefiled, refiled_notes: refiledNotes, is_note_changed: isNoteChanged } 
        this.isLoading = true
        editRefiledFlag(data).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.fetchDeedFiling(this.page, this.searchItem, this.filterWithUserId, this.filterWithRejected, this.filterWithCompleted, this.filterWithFiling, this.filterWithFiled, this.excludeTestDeeds, this.filterWithState)
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    markFilingCompleted(matterId: string, isFilingCompleted: boolean) {
        const data = { _id: matterId, is_filing_completed: isFilingCompleted } 
        this.isLoading = true
        editFilingCompleted(data).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.fetchDeedFiling(this.page, this.searchItem, this.filterWithUserId, this.filterWithRejected, this.filterWithCompleted, this.filterWithFiling, this.filterWithFiled, this.excludeTestDeeds, this.filterWithState)
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }
}

export default DeedFilingStore
