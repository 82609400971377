import React from 'react'
import { NavLink} from 'react-router-dom'
import RootStore from '../../store/Root'
import classNames from 'classnames'
import { version } from '../../../package.json'

interface Props {
    rootStore: RootStore
}

const NavBar: React.FC<Props> = ({ rootStore }) => {

    const toggle = () => {
        rootStore.navStore.collapse = !rootStore.navStore.collapse
    }
    const { isUserLoggedIn } = rootStore.authStore

    return <>
        <nav className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
            <NavLink className="navbar-brand col-md-3 col-lg-2 mr-0 px-3" to="/dashboard">Oath Zephyr <span className='version'>&nbsp;V.({version})</span></NavLink>

            <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-toggle="collapse" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation" onClick={toggle}>
                <span className="navbar-toggler-icon"></span>
            </button>

            <ul className="navbar-nav px-3 d-flex ">
                <li className={classNames('nav-item', { 'd-none': isUserLoggedIn })}>
                    <NavLink className="nav-link" to="login">Login</NavLink>
                </li>
            </ul>
        </nav>
    </>
}

export default NavBar
