import { action, observable } from 'mobx'
import RootStore from './Root'
import {addUpdateAddress, getAddresses, getSingleAddressDetails} from '../api/AddressActions'
import { AddressInformation, Dictionary } from './types.d'
import { initialTableDataWithPaginationDetails, initialSingleAddress } from './InitialValues'
import { toast } from 'react-toastify'

interface AddressData {
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    isFiltered: boolean,
    totalAllItems: number,
    page_items: Array<AddressInformation>
}

class AddressStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public addressDetails: AddressInformation
    @observable public addressData: AddressData
    @observable public searchItem: string
    @observable public page: number
    @observable public isPageOpened: boolean

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.searchItem = ''
        this.page = 1
        this.addressDetails = initialSingleAddress
        this.addressData = initialTableDataWithPaginationDetails
        this.isPageOpened = false
    }

    resetAddressDetails = (): void => {
        this.addressDetails = initialSingleAddress
    } 

    //get the list of addresss with details
    fetchAddresses = (page: number, searchItem: string): void => {
        this.isPageOpened = true
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''
        this.page = page
        this.searchItem = searchItem

        const requestData = { page: page, search_item: searchItem }

        getAddresses(requestData).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.addressData = response.data.data
            this.addressDetails = initialSingleAddress
        }).catch((error) => {

            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    //set the address details in addressDetails variable for show in update address page
    setAddressDetails = (data: string): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        getSingleAddressDetails(data).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.addressDetails = response.data.data
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    //update or delete the address details of given address_id
    addUpdateSingleAddress = (data: Dictionary<string>): any => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        return new Promise((resolve, reject) => {
            addUpdateAddress(data).then((response) => {
                this.isLoading = false

                if (response.data.success === 0) {
                    this.isApiError = true
                    this.apiErrorMessage = response.data.message
                    return
                }
                this.fetchAddresses(this.page, this.searchItem)
                resolve(response.data)

            }).catch((error) => {
                if (error.request.status === 401) {
                    this.rootStore.authStore.setAuthToken('')
                }

                this.isLoading = false
                this.isApiError = true
                this.apiErrorMessage = error.message
                reject(error.message)
            })
        })
    }
}

export default AddressStore
