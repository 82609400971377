import React from 'react'
import { handleKeyDownWithOutSpaceAtBeginning } from './WithoutAcceptSpace'
import classNames from 'classnames'

interface Props {
	label: string,
	fieldName: string
	// eslint-disable-next-line
	register: any
	// eslint-disable-next-line
	errors: any
	placeholder?: string
	defaultValue?: string
	required?: boolean
	disabled?: boolean
	helpText?: string
    needLabel?: boolean
    onChange?: (e: any) => void
    value?: string
}

const InputField: React.FC<Props> = ({ label, fieldName, register, errors, placeholder, defaultValue, required, disabled, helpText }) => {

    const fieldPlaceholder = placeholder ? placeholder : 'Enter ' + label

    return (
        <>
            <div className="form-group">
                <label>{label}</label>
                <input
                    ref={required ? register({
                        required: 'This field is required',
                        validate: (value: string) => (value.trim()) ? true : 'This field is required'
                    }) : register}
                    name={fieldName}
                    type='text'
                    onKeyDown={handleKeyDownWithOutSpaceAtBeginning}
                    className={classNames('form-control', { 'is-invalid': errors })}
                    placeholder={fieldPlaceholder}
                    defaultValue={defaultValue}
                    disabled={disabled}
                />
                {errors ? (<div className='invalid-feedback'>{errors.message}</div>) : <small className='form-text text-muted'>{helpText}</small>}
            </div>
        </>
    )
}


export const InputFieldForVisionPlan: React.FC<Props> = ({ label, fieldName, register, errors, placeholder, defaultValue, required, disabled, helpText, needLabel=true }) => {

    const fieldPlaceholder = placeholder ? placeholder : 'Enter ' + label

    return (

        <div className="form-group col">
            {needLabel ? <label>{label}</label> : null }
            <input
                ref={required ? register({
                    required: 'This field is required',
                    validate: (value: string) => (value.trim()) ? true : 'This field is required'
                }) : register}
                name={fieldName}
                type='text'
                onKeyDown={handleKeyDownWithOutSpaceAtBeginning}
                className={classNames('form-control', { 'is-invalid': errors })}
                placeholder={fieldPlaceholder}
                defaultValue={defaultValue}
                disabled={disabled}
            />
            {errors ? (<div className='invalid-feedback'>{errors.message}</div>) : <small className='form-text text-muted'>{helpText}</small>}
        </div>
    )
}

export const InputFieldForPlanPricing: React.FC<Props> = ({ label, value, fieldName, register, errors, placeholder, required, disabled, helpText, needLabel=true, onChange }) => {

    const fieldPlaceholder = placeholder ? placeholder : 'Enter ' + label
    return (
        <div className="form-group col">
            {needLabel ? <label>{label}</label> : null }
            <input
                ref={required ? register({
                    required: 'This field is required',
                    validate: (value: string) => (value.trim()) ? true : 'This field is required'
                }) : register}
                name={fieldName}
                type='text'
                onKeyDown={handleKeyDownWithOutSpaceAtBeginning}
                className={classNames('form-control', { 'is-invalid': errors })}
                placeholder={fieldPlaceholder}
                disabled={disabled}
                onChange={onChange}
                defaultValue={value}
            />
            {errors ? (<div className='invalid-feedback'>{errors.message}</div>) : <small className='form-text text-muted'>{helpText}</small>}
        </div>
    )
}

export default InputField